import { useRouteError } from "react-router-dom";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { Box, Typography } from "@mui/material";

export default function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReportGmailerrorredOutlinedIcon />
      <Typography variant="string" component="p" sx={{ marginLeft: "5px" }}>
        Error {error.statusText || error.message}
      </Typography>
    </Box>
  );
}
