import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Skeleton, Button } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import { experimentalStyled as styled } from "@mui/material/styles";

import Device from "../components/Device.mjs";
import DateWindow from "../components/DateWindow.mjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RuleIcon from "@mui/icons-material/Rule";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FindInPageIcon from "@mui/icons-material/FindInPage";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const DeviceButton = styled(Button)(({ theme }) => ({
  minWidth: 110,
	backgroundColor:"#0442960D",
	color: "#000000CC",
	fontSize:"16px",
	"&:hover":{ background:"#005BD4", color: "white"}
}));

function noDataReceived() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <InfoOutlinedIcon />
      <Typography variant="string" component="p" sx={{ marginLeft: "5px" }}>
        No data has been received for this device yet.
      </Typography>
    </Box>
  );
}

function waterChart(chartType, chartsData) {
  return (
    <LineChart
      key={chartType}
      xAxis={[
        {
          scaleType: "time",
          data: chartsData.get("sampleDate"),
        },
      ]}
      series={[
        {
          data: chartsData.get(chartType),
          label: chartsData.get("legend")[chartType],
					color:"#FFAC1C"
        },
      ]}
      sx={{
				display: "flex",
        justifyContent: "center",
        width: "100%",
        border: 1,
        borderColor: "common.grey25",
				paddingBottom:0,
				borderRadius:"10px",
				marginBottom:"10px" }}
				width={400}
				height={300}

    />
  );
}

function renderCharts(chartsData) {
  const availableChartTypes = chartsData.keys();
  const charts = [];
  let result = noDataReceived();
  //let result = <Error error={new Error("400")} />;

  let chartType = availableChartTypes.next();
  while (!chartType.done) {
    if (chartType.value !== "legend" && chartType.value !== "sampleDate") {
      charts.push(waterChart(chartType.value, chartsData));
    }

    chartType = availableChartTypes.next();
  }

  if (charts.length) {
    result = (
      <Box
        sx={{
          m: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {charts}
      </Box>
    );
  }

  return result;
}

export default function Devices() {
  const { deviceId, orgId } = useParams();
  const [syncNeeded, setSyncNeeded] = useState(true);
  const [charts, setCharts] = useState(null);

  const timeWindow = useStoreState((state) => state.telemetry.timeWindow);
  const pollingId = useStoreState((state) => state.telemetry.polling);

  const getTelemerty = useStoreActions(
    (actions) => actions.telemetry.getTelemetryForTime,
  );

  const pollTelemerty = useStoreActions(
    (actions) => actions.telemetry.pollTelemetryForTime,
  );

  const setPolling = useStoreActions((actions) => actions.telemetry.setPolling);
  const stopPolling = useStoreActions(
    (actions) => actions.telemetry.stopPolling,
  );

  const setCurrentDeviceId = useStoreActions(
    (actions) => actions.setCurrentDeviceId,
  );

  const setCurrentProjectId = useStoreActions(
    (actions) => actions.setCurrentProjectId,
  );

  const deviceTelemetry = useStoreState((state) => state.telemetry.data);

  //onUnmount halt polling
  useEffect(
    () => () => {
      //don't allow to execute more frequently than 1 in 5sec
      let throt_setPoll = _.throttle(function () {
        stopPolling();
      }, 5000);

      throt_setPoll();
    },
    [],
  );

  useEffect(() => {
    if (!syncNeeded) {
      setSyncNeeded(true);
    }
  }, [timeWindow]);

  useEffect(() => {
    if (syncNeeded) {
      setCurrentDeviceId(deviceId);
      setCurrentProjectId(orgId);

      //don't allow to execute more frequently than 1 in 5sec
      let throt_poll = _.throttle(function () {
        if (pollingId < -1) {
          setPolling(-1);
        }
        pollTelemerty();
      }, 5000);

      throt_poll();
    }

    setSyncNeeded(false);
  }, [syncNeeded]);

  useEffect(() => {
    if (deviceTelemetry?.size) {
      setCharts(renderCharts(deviceTelemetry));
    }
  }, [deviceTelemetry]);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        marginTop: "2em",
      }}
    >
      {!deviceTelemetry?.size ? (
        <Skeleton variant="rounded" animation="wave" width={210} height={60} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            component="header"
            sx={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
							gap:"10px"
            }}
          >
            <Device />
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                maxWidth: 300,
                minWidth: 320,
                justifyContent: "center",
                marginTop: 1,
                marginBottom: 1,
              }}
            >
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {/* <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<AutoGraphIcon />}
                  >
                    Graphs
                  </DeviceButton> */}
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<SdCardAlertIcon />}
                  >
                    Alerts
                  </DeviceButton>
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<EventAvailableIcon />}
                  >
                    Events
                  </DeviceButton>
									<DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<RuleIcon />}
                  >
                    Rules
                  </DeviceButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<InsertDriveFileIcon />}
                  >
                    Docs
                  </DeviceButton>
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<FindInPageIcon />}
                  >
                    Audit
                  </DeviceButton>
                </Box>
              </ThemeProvider>
            </Box>
          </Box>
          <Box 
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						// border: 1,
						// borderColor: "common.grey25",
						// background:"#f0f0f033",
						flexDirection: "column",
						marginTop:"1em",
						padding:"10px",
						borderRadius: "10px"
      		}}>
            <DateWindow />
            <Box sx={{
							"& .MuiBox-root":{margin:0, marginTop:"10px"}
							}} 
							component="article">{charts}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
