import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useStoreActions, useStoreState } from "easy-peasy";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* function DeviceDetails(device) {
  return (
    <Box>
      <Typography>{device.displayName}</Typography>
    </Box>
  );
} */

export default function Device() {
  const { deviceId, orgId } = useParams();
  const [device, setDevice] = useState(null);
  const [projectDescription, setProjectDescription] = useState(null);
  //get the tree, then get the organization by id, then get device by Id
  //then render the darn thing here
  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);
  const loadDeviceHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  useEffect(() => {
    if (deviceHierarchy) {
      let org = deviceHierarchy.getOrganizationById(orgId);
      setDevice(
        org?.devices.filter((device) => device.id === deviceId).at(0) ||
          org.devices.at(0),
      );
      setProjectDescription(org?.projectDescription);
    } else {
      loadDeviceHierarchy();
    }
  }, [deviceHierarchy]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        border: 1,
        borderColor: "common.grey25",
				maxWidth: 360,
				padding:2,
				paddingBottom:0,
				borderRadius:"10px"
      }}
    >
      {deviceHierarchy ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
						gap:2
          }}
        >
					<Box><Typography>{projectDescription?.address}</Typography></Box>
					<Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
						<Box>
							<Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
							gap:"10px"
            }}>
							<Box 
								sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center"
            }}>
								<Box><Typography>{`State:`}</Typography></Box>
								<Box 
								sx={{
									marginLeft:"10px", 
									backgroundColor:"common.lightGreen", 
									padding:"1px 6px", 
									borderRadius:"5px", 
									color: "white"
									}}>
										<Typography color="common.white">
											{`${device?.connectionState ? "Connected" : "Disconnected"}`}
											</Typography>
								</Box>
							</Box>
							<Box 
								sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center"
            }}>
								<Box><Typography>{`Valve:`}</Typography></Box>
								<Box sx={{
									marginLeft:"10px", 
									backgroundColor:"common.brightBlue", 
									padding:"1px 6px", 
									borderRadius:"5px", 
									color: "white"
									}}><Typography color="common.white">{`${device?.valvePosition ? "Open" : "Closed"}`}</Typography></Box>
							</Box>
							</Box>
						</Box>
						<Box><Typography>{projectDescription?.projectReference}</Typography></Box>
					</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
          </Box>
					<Box>
					<Accordion sx={{
              boxShadow: "none"
            }}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
					sx={{
						padding:0,
						"& .MuiAccordionSummary-content": {
          borderRadius: "1px",
					justifyContent: "flex-end"
        },
					}}
        >
          <Typography>more info</Typography>
        </AccordionSummary>
        <AccordionDetails 
				sx={{
              border:0,
							padding:0,
							paddingBottom:"16px"
            }}>
          <Typography>
            more information here
          </Typography>
        </AccordionDetails>
      </Accordion>
					</Box>
        </Box>
      ) : (
        <Skeleton variant="rounded" animation="wave" width={210} height={50} />
      )}
    </Box>
  );
}
