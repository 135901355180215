import OrganizationNode from "./OrganizationNode.mjs";

/**
 * This class encapsulates the logic of storing and searching IoT Central Organizations.
 * Authorization to show the orpahaned organization filled with unassigned devices is based on isAdminUser parameter.
 */
export default class OrganizationTree {
  static ORPHANED_ORG_ID = "orphaned";
  #rawOrgs;
  #tree;

  constructor(orgs = []) {
    this.#rawOrgs = orgs;
    this.#tree = Array.isArray(orgs)
      ? this.#buildOrgTree(
          new OrganizationNode(
            orgs[0].orgId,
            orgs[0].displayName,
            orgs[0].childNodes,
            orgs[0].parentId,
            orgs[0].devices,
            orgs[0].projectDescription,
          ),
        )
      : {};
  }

  /**
   * A recurcive BFS (Breadth first search) of a OrganizationTree.#tree
   * @param {OrganizationNode} node A node to start search from.
   * @param {string} orgId
   * @returns {OrganizationNode} The found node.
   */
  #searchTreeById(node, orgId) {
    if (!node) return;

    if (node.orgId === orgId) {
      return node;
    }

    for (let i = 0; i < node.childNodes.length; i++) {
      //if you don't declare and check for result here, the function will not return the result
      const result = this.#searchTreeById(node.childNodes[i], orgId);
      if (result) {
        return result;
      }
    }
  }

  #searchTreeForProjects(node, accumulator) {
    if (!node) return;

    if (node.devices.length > 0 /* || node.id === "orphaned" */) {
      accumulator.push(node.clone());
    }

    for (let i = 0; i < node.childNodes.length; i++) {
      //if you don't declare and check for result here, the function will not return the result.
      this.#searchTreeForProjects(node.childNodes[i], accumulator);
    }
  }

  getProjects() {
    const accumulator = [];
    //provide the root of the tree as first node
    this.#searchTreeForProjects(this.#tree, accumulator);

    return accumulator;
  }

  getOrganizationById(orgId) {
    //provide the root of the tree as first node
    let org = this.#searchTreeById(this.#tree, orgId);

    if (!org) {
      org = new OrganizationNode(orgId); //dummy
    }

    return org;
  }

  /**
   * Recurse through the raw JSON tree that the server provides us and convert (clone) it
   * into our own UI tree that we can render from and/or update without worrying that it will change the original data.
   * This effectively creates a more maintainable interface on the client, so that server changes can be easily captured and dealt with.
   * @param {OrganizationNode} node to start from
   * @returns {OrganizationNode} Tree of OrganizationNodes
   */
  #buildOrgTree(node) {
    for (let i = 0; i < node.childNodes.length; i++) {
      node.childNodes[i] = this.#buildOrgTree(
        new OrganizationNode(
          node.childNodes[i].orgId,
          node.childNodes[i].displayName,
          node.childNodes[i].childNodes,
          node.childNodes[i].parentId,
          node.childNodes[i].devices,
          node.childNodes[i].projectDescription,
        ),
      );
    }

    return node;
  }

  get tree() {
    return this.#tree;
  }

  clone() {
    return new OrganizationTree(this.#rawOrgs);
  }

  toJSON() {
    return tree();
  }
}
