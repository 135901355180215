import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import * as atlas from 'azure-maps-control'
import { AZURE_MAPS_SUBSCRIPTION_KEY } from '../constants'
import 'azure-maps-control/dist/atlas.min.css'
import {
  Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Chip,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import Box from "@mui/material/Box";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PlaceIcon from '@mui/icons-material/Place';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, .06)'
        }
      }
    },
    MuiAccordionSummary : {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '45px'
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '0',
          },
          fontWeight: 'bold',
          flex: 0,
        },
      },
    },
  },
});

const AzureMapComponent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false); // Track if the accordion is expanded
  const [devices, setDevices] = useState([]);
  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);
  const loadDeviceHierarchy = useStoreActions(
      (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  const mapRef = useRef(null)
  const mapInstanceRef = useRef(null); // Reference to the map instance
  const [selectedDeviceId, setSelectedDeviceId] = useState(null); // Track selected device ID
  const markersRef = useRef([]); // Store references to the markers
  const listItemRefs = useRef({}); // Store refs for each list item
  
  const defaultPosition = [-123.002998, 49.287327];

  useEffect(() => {
    try {
      if (deviceHierarchy) {
        setLoading(false);
        const projects = deviceHierarchy.getProjects();
  
        if (projects.length > 0) {
          setDevices(projects.map((project) => ({
            id: project?.devices?.at(0)?.id,
            name: project?.devices?.at(0)?.displayName || 'Unknown Device',
            // position: [ -123.002998,  49.287327 ],
            position: project?.projectDescription?.location?.reverse(),
            address: project?.projectDescription?.address || 'Unknown Address',
            status: project?.projectDescription?.isActive,
            valvePosition: project?.projectDescription?.valvePosition,
          })));
        } else {
          setError('No devices found');
        }

        // setDevices(
        // [
        //     {
        //       id: 1,
        //       name: 'displayName 1',
        //       position: [ -123.002998,  49.287327 ],
        //       address:'123 Mulholland dr',
        //     },
        //     {
        //       id: 2,
        //       name: 'displayName 2',
        //       position: [ -123.102998,  49.387327 ],
        //       address:'456 Francis dr',
        //     },
        //     {
        //       id: 3,
        //       name: 'displayName 3',
        //       position: [  -122.917099, 49.210116 ],
        //       address:'789 South dr',
        //     },
        //     {
        //       id: 4,
        //       name: 'displayName 4',
        //       position: [ -122.62, 49.18 ],
        //       address:'908 Abra dr',
        //     },
        //     {
        //       id: 5,
        //       name: 'displayName 5',
        //       position: [ -122.52, 49.090116 ],
        //       address:'12 Seem dr',
        //     },
        //     {
        //       id: 6,
        //       name: 'displayName 6',
        //       position: [ -122.95, 49.15 ],
        //       address:'1256 Wood dr',
        //     },
        // ]);
      } else {
        loadDeviceHierarchy().catch(() => {
          setError('Failed to load devices');
          setLoading(false);
        });
      }
    } catch (err) {
      setError('An unexpected error occurred');
      setLoading(false);
    }
  }, [deviceHierarchy]);
  
  const mapOptions = useMemo(() => ({
    authOptions: {
      authType: atlas.AuthenticationType.subscriptionKey,
      subscriptionKey: AZURE_MAPS_SUBSCRIPTION_KEY,
    },
    center: devices.length === 1 ? devices[0].position : defaultPosition,
    zoom: 10,
    view: 'Auto',
  }), [devices]);
  
  // Set map bounds to include all devices, or zoom out to show the world
  const mapBoundBox = (map) => {
    const validDevices = devices.filter(device => device.position?.length === 2); // Filter out invalid positions
  
    if (validDevices.length > 1) {
      const boundingBox = atlas.data.BoundingBox.fromPositions(
        validDevices.map((device) => device.position)
      );
      map.setCamera({ bounds: boundingBox, padding: 80 });
    } else if (validDevices.length === 1) {
      map.setCamera({ center: validDevices[0].position, zoom: 10 }); // Set zoom to 10 for one valid device
    } else {
      map.setCamera({ center: defaultPosition, zoom: 1 }); // Zoom out to show the whole world
    }
  }
  
  useEffect(() => {
    if (!mapRef.current) return;

    // Initialize the map instance
    const map = new atlas.Map(mapRef.current, mapOptions);
    mapInstanceRef.current = map; // Store the map instance

    map.controls.add(
        [
          new atlas.control.ZoomControl(),
          new atlas.control.CompassControl(),
          new atlas.control.PitchControl(),
          new atlas.control.StyleControl()
        ],
        { position: 'top-right' }
    );
  
    mapBoundBox(map);
    
    map.events.add('ready', () => {
      // Clear previous markers
      markersRef.current.forEach(({ marker }) => {
        map.markers.remove(marker);
      });
      markersRef.current = []; // Reset markers ref
      
      devices.forEach((device, index) => {
        const marker = new atlas.HtmlMarker({
          color: 'DodgerBlue', // Default color
          // text: index,
          htmlContent: atlas.getImageTemplate('pin', 1.2),
          position: device.position || [0, 0],
        });

        map.markers.add(marker);
        markersRef.current.push({ deviceId: device.id, marker }); // Store markers

        // Event listener for clicking a marker
        map.events.add('click', marker, () => {
          setSelectedDeviceId(device.id);
        });
      });
    });

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.dispose();
      }
    };
  }, [devices]);

  // Update the selected marker's color and map camera when the selected device changes
  useEffect(() => {
    if (selectedDeviceId !== null && mapInstanceRef.current) {
      markersRef.current.forEach(({ deviceId, marker }) => {
        marker.setOptions({ color: deviceId === selectedDeviceId ? 'Red' : 'DodgerBlue' });
        if (deviceId === selectedDeviceId && listItemRefs.current[deviceId]) {
          listItemRefs.current[deviceId].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      });
    }
  }, [selectedDeviceId]);
  
  const handleDeviceToMapClick = useCallback((id) => {
    setSelectedDeviceId(id);
    
    // Ensure the accordion is expanded when a device is clicked
    setExpanded(true);
    
    if (mapInstanceRef.current && devices.length > 1) {
      mapBoundBox(mapInstanceRef.current);
    }
  }, [devices]);
  
  const renderDeviceList = (device) => (
    <React.Fragment key={device.id}>
      <Card sx={{ backgroundColor: selectedDeviceId === device.id ? 'rgba(0, 0, 0, .06)' : 'inherit' }}>
        <CardContent>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            height: '100%'
          }}>
            <Stack>
              <Typography variant="h6" component="h2">{device.name}</Typography>
              <Typography variant="body2" color="textSecondary">{device.address}</Typography>
            </Stack>
  
            {device.position?.length === 2 && (
              <Stack
                onClick={() => handleDeviceToMapClick(device.id)}
                direction="column"
                sx={{ alignItems: 'center', cursor: 'pointer' }} // Add cursor pointer for clickable effect
              >
                <Typography color="textSecondary">Map</Typography>
                <PlaceIcon fontSize="large" sx={{ color: selectedDeviceId === device.id ? 'red' : 'grey' }}/>
              </Stack>
            )}
          </Box>
          
          <Divider/>
          
          <Box ref={(el) => (listItemRefs.current[device.id] = el)}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: 'auto auto',
              gap: '5px',
              gridTemplateAreas: `
                "a b"
                "d e"
              `,
              margin: "10px 0",
              alignItems: 'center',
            }}
          >
            {/* Device Status */}
            <Box sx={{ gridArea: 'a' }}>
              <Typography variant="body2">Unit Status:</Typography>
            </Box>
            <Box sx={{ gridArea: 'b' }}>
              {device.status ? (
                <Chip label={device.status ? "Active" : "Inactive"} color="success" size="small" sx={{ minWidth: "200px" }} />
                ) : (
                <Chip label="Inactive" size="small" sx={{ width: "100%" }} variant="outlined" />
              )}
            </Box>
    
            {/* Flow Control */}
            <Box sx={{ gridArea: 'd' }}>
              <Typography variant="body2">Flow Control:</Typography>
            </Box>
            <Box sx={{ gridArea: 'e' }}>
              <Chip label={device.valvePosition || 'N/A'} color="primary" size="small" sx={{ minWidth: "200px", textTransform: "capitalize" }} />
            </Box>
            
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  )
  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded); // Toggle the accordion map
  };
  
  if (loading) return (
    <Box sx={{
      display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(60dvh - 150px)',
      }}>
      <CircularProgress/>
    </Box>);
  
  if (error) return (<Stack><Alert severity="error">{error}</Alert></Stack>);
  
  return (
    <ThemeProvider theme={theme}>
      <>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        // slotProps={{ heading: { component: 'h4' } }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Map
        </AccordionSummary>
        <AccordionDetails>
          <Box ref={mapRef} sx={{ height: 'calc(60dvh - 260px)', width: '100%' }}></Box>
        </AccordionDetails>
      </Accordion>
  
  
      <Box sx={
        {
          padding: '20px 0',
          maxHeight: expanded ? '35dvh' : 'calc(100dvh - 260px)',
          overflowY: 'auto'
        }
      }>
        {devices.length === 0 ? (
          <Stack sx={{ textAlign: 'center' }}>
            <Alert severity="error">No devices found</Alert>
          </Stack>
        ) : (
          <List component="nav" aria-label="devices" sx={{ padding: 0 }}>
            {devices.map((device) => renderDeviceList(device))}
          </List>
        )}
      </Box>
    </>
    </ThemeProvider>
  );
};

export default AzureMapComponent;
