import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Box, Typography, Skeleton } from "@mui/material";

function Rule({ rule }) {
  return (
    <Box>
      <Typography>{rule.displayName}</Typography>
      <Typography>{rule.condition}</Typography>
      <Typography>{rule.severity}</Typography>
    </Box>
  );
}

function RuleList({ rules }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      {rules.map((rule) => (
        <Rule key={rule.id} rule={rule} />
      ))}
    </Box>
  );
}

export default function Rules() {
  const getRules = useStoreActions((actions) => actions.rules.getRules);

  const alertRules = useStoreState((state) => state.rules.data);

  useEffect(() => {
    getRules();
  }, []);

  return (
    <Box component="section">
      {!alertRules?.length ? (
        <Skeleton variant="rounded" animation="wave" width={210} height={60} />
      ) : (
        <RuleList rules={alertRules} />
      )}
    </Box>
  );
}
