import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function DeviceList({ data }) {
  const deviceList = data.devices.map((device) => {
    return (
      <Button
        key={device.id}
        component={Link}
        to={`projects/${data.orgId}/devices/${device.id}`}
      >
        {device.displayName}
      </Button>
    );
  });

  return deviceList[0];
}
