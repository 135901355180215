import TelemetryDevice from "./TelemetryDevice.mjs";
import ProjectDescription from "./ProjectDescription.mjs";

export default class OrganizationNode {
  //(read-only) when in the organizaitons container we need a way to differentiate between organizations and org details and rules.
  #recordType = "organization";
  #orgId;
  #displayName;
  #childNodes;
  #parentId;
  #devices;
  #projectDescription;

  constructor(
    orgId = "",
    displayName = "Project Not Found",
    childNodes = [],
    parentId = "*",
    devices = [],
    projDesc,
  ) {
    this.#orgId = orgId;
    this.#displayName = displayName;
    this.#childNodes = childNodes;
    this.#parentId = parentId;

    /* const emptyDevice = {
      id: "N/A",
      displayName: "N/A",
      isEnabled: false,
      isProvisioned: false,
      template: "N/A",
      organizations: [],
      valvePosition: 0,
      connectionState: "N/A",
    }; */

    /* id,
    displayName,
    isEnabled,
    isProvisioned,
    template,
    organizations,
    valvePosition,
    connectionState, */

    //id no devices insert a dummy
    this.#devices = devices?.length
      ? devices.map(
          (device) =>
            new TelemetryDevice(
              device.id,
              device.displayName,
              device.isEnabled,
              device.isProvisioned,
              device.template,
              device.organizations,
              device.valvePosition,
              device.connectionState,
            ),
        )
      : devices;

    //if there is no description insert a dummy
    this.#projectDescription = projDesc
      ? new ProjectDescription(
          projDesc.id,
          projDesc.orgId,
          projDesc.address,
          projDesc.projectReference,
          projDesc.serialNumber,
          projDesc.escPermit,
          projDesc.escDesigner,
          projDesc.escMonitor,
          projDesc.maintenanceInterval,
          projDesc.installerName,
          projDesc.flowRate,
          projDesc.planId,
          projDesc.location,
          projDesc.lastVisit,
          projDesc.lastMaintenance,
          projDesc.lastSeriousRainfallEvent,
          projDesc.lastDischarge,
          projDesc.dischargeDestination,
          projDesc.wqCompliance,
          projDesc.isActive,
          projDesc.valvePosition,
          projDesc.healthState,
          projDesc.contacts,
          projDesc.description,
        )
      : new ProjectDescription();
  }

  get recordType() {
    return this.#recordType;
  }

  get orgId() {
    return this.#orgId;
  }

  set orgId(id) {
    this.#orgId = id;
  }

  get displayName() {
    return this.#displayName;
  }

  set displayName(displayName) {
    this.#displayName = displayName;
  }

  get childNodes() {
    return this.#childNodes;
  }

  set childNodes(childNodes) {
    this.#childNodes = childNodes;
  }

  get parentId() {
    return this.#parentId;
  }

  set parentId(parentId) {
    this.#parentId = parentId;
  }

  get devices() {
    return this.#devices;
  }

  set devices(devices) {
    this.#devices = devices;
  }

  get projectDescription() {
    return this.#projectDescription;
  }

  set projectDescription(projectDescription) {
    this.#projectDescription = projectDescription;
  }

  //Deep clone
  clone() {
    return new OrganizationNode(
      this.#orgId,
      this.#displayName,
      this.#childNodes.map((childOrg) => childOrg.clone()),
      this.#parentId,
      this.#devices.map((device) => device.clone()),
      this.#projectDescription ? this.#projectDescription.clone() : null,
    );
  }

  toJSON() {
    return {
      recordType: this.#recordType,
      orgId: this.#orgId,
      displayName: this.#displayName,
      childNodes: this.#childNodes,
      parentId: this.#parentId,
      devices: this.#devices,
      projectDescription: this.#projectDescription,
    };
  }
}
