import { createTheme } from "@mui/material/styles";


const renBlue = "#044296";
const renLightBlue = "#2493F2";
const renBrightBlue = "#005BD4";
const renGreyBlue = "#057AE3";
const renDarkGreen = "#2B6000";
const renLightGreen = "#6CBC34";
const grey50 = "#00000080";
const grey25 = "#00000040";
const grey75 = "#000000BF";
const black = "#000000";
const white = "#ffffff";

const arcOrange = "#FFAC1C";

const theme = createTheme({
  palette: {
    mode: "light",
    common: {
      blue: `${renBlue}`,
			lightBlue: `${renLightBlue}`,
			brightBlue: `${renBrightBlue}`,
			greyBlue: `${renGreyBlue}`,
			darkGreen: `${renDarkGreen}`,
			lightGreen: `${renLightGreen}`,
			grey50: `${grey50}`,
			grey75: `${grey75}`,
			grey25: `${grey25}`,
			black: `${black}`,
      orange: `${arcOrange}`,
    },
    primary: {
      main: `${renBlue}`,
    },
    secondary: {
      main: `${grey50}`,
    },
		tetriary: {
      main: `${renLightGreen}`,
    },
		black: {
      main: `${black}`,
    },
		white: {
      main: `${white}`,
    },
  },
  typography: {
		fontFamily: "Helvetica",
    body1: {
			fontSize: "1rem",
		},
		body2: {
			fontSize: "0.875rem",
		},
		h1: {
			fontSize: "3.5rem",
			fontWeight: 500,
		},
		h2: {
			fontSize: "2.5rem",
			fontWeight: 700,
		},
		h3: {
			fontSize: "1.5rem",
			fontWeight: 700,
		},
		h4: {
			fontSize: "1rem",
			fontWeight: 700,
		},
  },
});


export default theme;
