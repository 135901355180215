export default class TelemetryDevice {
  #id;
  #displayName;
  #isEnabled;
  #isProvisioned;
  #organizations;
  #template;
  #valvePosition;
  #connectionState;

  constructor(
    id,
    displayName,
    isEnabled,
    isProvisioned,
    template,
    organizations,
    valvePosition,
    connectionState,
  ) {
    this.#id = id;
    this.#displayName = displayName;
    this.#isEnabled = isEnabled;
    this.isProvisioned = isProvisioned;
    this.#template = template;
    this.#organizations = organizations;
    this.#valvePosition = valvePosition;
    this.#connectionState = connectionState;
  }

  get id() {
    return this.#id;
  }

  set id(id) {
    this.#id = id;
  }

  get displayName() {
    return this.#displayName;
  }

  set displayName(displayName) {
    this.#displayName = displayName;
  }

  get isEnabled() {
    return this.#isEnabled;
  }

  set isEnabled(isEnabled) {
    this.#isEnabled = isEnabled;
  }

  get isProvisioned() {
    return this.#isProvisioned;
  }

  set isProvisioned(isProvisioned) {
    this.#isProvisioned = isProvisioned;
  }

  get template() {
    return this.#template;
  }

  set template(template) {
    this.#template = template;
  }

  get organizations() {
    return this.#organizations;
  }

  set organizations(organizations) {
    this.#organizations = organizations;
  }

  get valvePosition() {
    return this.#valvePosition;
  }

  set valvePosition(valvePosition) {
    this.#valvePosition = valvePosition;
  }

  get connectionState() {
    return this.#connectionState;
  }

  set connectionState(connectionState) {
    this.#connectionState = connectionState;
  }

  clone() {
    return new TelemetryDevice(
      this.#id,
      this.#displayName,
      this.#isEnabled,
      this.isProvisioned,
      this.#template,
      this.#organizations,
      this.#valvePosition,
      this.#connectionState,
    );
  }
  //since devices contain simple property values we don't need to call toJSON on each device here. No deep cloning needed.
  toJSON() {
    return {
      id: this.#id,
      displayName: this.#displayName,
      isEnabled: this.#isEnabled,
      isProvisioned: this.#isProvisioned,
      template: this.#template,
      organizations: this.#organizations,
      valvePosition: this.#valvePosition,
      connectionState: this.#connectionState,
    };
  }
}
