export default class WqmsError extends Error {
  #status;

  constructor(msg = "WQMS Error!", status = 500) {
    super(msg);
    //override
    this.name = "WQMSError";
    this.#status = status;
  }

  get status() {
    return this.#status;
  }

  set status(statusCode) {
    this.#status = statusCode;
  }

  toJSON() {
    return { name: this.name, error: this.message, status: this.#status };
  }
}
