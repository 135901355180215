/**
 * Represents a contact information of a person who is related to the project.
 */
export default class ProjectContact {
  #id;
  #firstName;
  #lastName;
  #email;
  #phone;
  #isExternal;
  #position;

  /**
   * Creates an instance of Contact.
   * @param {number} id - The unique identifier for the Contact.
   * @param {string} firstName - The first name of the Contact.
   * @param {string} lastName - The last name of the Contact.
   * @param {string} email - The email address of the Contact.
   * @param {string} phone - The phone number of the Contact.
   * @param {boolean} isExternal - Indicates if the Contact is external.
   * @param {string} position - The position of the Contact.
   */
  constructor({ id, firstName, lastName, email, phone, isExternal, position }) {
    this.#id = id;
    this.#firstName = firstName;
    this.#lastName = lastName;
    this.#email = email;
    this.#phone = phone;
    this.#isExternal = isExternal;
    this.#position = position;
  }

  // Getters
  get id() {
    return this.#id;
  }

  get firstName() {
    return this.#firstName;
  }

  get lastName() {
    return this.#lastName;
  }

  get email() {
    return this.#email;
  }

  get phone() {
    return this.#phone;
  }

  get isExternal() {
    return this.#isExternal;
  }

  get position() {
    return this.#position;
  }

  // Setters
  set id(value) {
    this.#id = value;
  }

  set firstName(value) {
    this.#firstName = value;
  }

  set lastName(value) {
    this.#lastName = value;
  }

  set email(value) {
    this.#email = value;
  }

  set phone(value) {
    this.#phone = value;
  }

  set isExternal(value) {
    this.#isExternal = value;
  }

  set position(value) {
    this.#position = value;
  }

  clone() {
    return new ProjectContact(
      this.#id,
      this.#firstName,
      this.#lastName,
      this.#email,
      this.#phone,
      this.#isExternal,
      this.#position,
    );
  }

  // toJSON method
  toJSON() {
    return {
      id: this.#id,
      firstName: this.#firstName,
      lastName: this.#lastName,
      email: this.#email,
      phone: this.#phone,
      isExternal: this.#isExternal,
      position: this.#position,
    };
  }
}
