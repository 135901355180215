import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { DateTime } from "luxon";

const columns = [
  { field: "id", headerName: "ID", width: 10 },
  {
    field: "eventType",
    headerName: "Event Type",
    width: 90,
    editable: false,
  },
  {
    field: "startedAt",
    headerName: "Occured at",
    width: 180,
    valueFormatter: (value, row) => {
      const date = DateTime.fromISO(value /* { zone: "utc" } */);
      return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    editable: false,
  },
  {
    field: "deviceName",
    headerName: "Device name",
    width: 150,
    editable: false,
  },
  {
    field: "eventValue",
    headerName: "Result",
    width: 120,
    editable: false,
  },

  /* {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  }, */
];

const rows = [
  {
    id: 1,
    eventType: "Alert",
    startedAt: "2024-06-21T18:12:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "ph > 8",
  },
  {
    id: 2,
    eventType: "Alert",
    startedAt: "2024-06-21T18:13:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "Turbidity > 100",
  },
  {
    id: 3,
    eventType: "Alert",
    startedAt: "2024-06-21T18:14:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "Turbidity > 50",
  },
  {
    id: 4,
    eventType: "Alert",
    startedAt: "2024-06-21T18:15:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "ph < 4",
  },
  {
    id: 5,
    eventType: "Alert",
    startedAt: "2024-06-21T18:16:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "ph < 4",
  },
];

export default function Alerts() {
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
