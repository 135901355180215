import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  useScrollTrigger,
  Toolbar,
  ThemeProvider,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link, useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import _ from "lodash";

import theme from "../pages/Theme";
import logo from "../../public/assets/windows11/WhiteLogo.png";
import MainMenu from "./MainMenu";

/**
 * This component adds the "floating" effect to it's children during scrolling
 */
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true, //delay when user is scrolling
    threshold: 0, //how far to scroll before the listener triggers
  });

  //Return the !clone! of children with elevation if triggered
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function HeadingText({ pageName }) {
  //let name = pageName.replace(/\//, "");
  const withoutFwdSlash = pageName.substring(1);
  const getCurrentProject = useStoreState(
    (state) => state.orgHierarchy.currentProject,
  );
  const projectId = useStoreState((state) => state.currentProjId);
  let name = "";
  //  withoutFwdSlash === "" ? "Projects" : withoutFwdSlash.replace(/\/.*/, "");

  if (withoutFwdSlash === "") {
    //root
    name = "Projects";
  } else if (withoutFwdSlash.replace(/\/.*/, "") === "projects") {
    //We're on project properties/telemetry page. Render the project name.
    name = getCurrentProject(projectId)?.displayName;
  } else {
    //some other path
    name = _.upperFirst(withoutFwdSlash);
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Link to="/">
        <IconButton aria-label="Back">
          <ArrowBackOutlinedIcon color="white" />
        </IconButton>
      </Link>
      <Typography variant="h4" color="white" sx={{ ml: 2 }}>
        {name}
      </Typography>
    </Box>
  );
}

export default function Header() {
  const currentURL = useLocation().pathname;
  const [isAtRoot, setIsAtRoot] = useState(true);
  const toolbarStyle = {
    minHeight: "50px",
  };

  useEffect(() => {
    setIsAtRoot(currentURL === "/");
  }, [currentURL]);

  return (
    <React.Fragment key="left">
      <ElevationScroll>
        <AppBar color="primary" sx={{ height: 50 }}>
          <Toolbar
            style={toolbarStyle}
            disableGutters
            sx={{
              pl: 2,
              pr: 2,
              height: 50,
              minHeight: 50,
              justifyContent: "space-between",
            }}
          >
            {isAtRoot ? (
              <Box
                component="img"
                alt="company logo"
                src={logo}
                sx={{ width: 40 }}
              />
            ) : (
              <Box
                component="img"
                alt="company logo"
                src={logo}
                sx={{ width: 40 }}
              />
            )}

            {isAtRoot ? (
              <Typography variant="h4" color="white" sx={{ ml: 2 }}>
                Projects
              </Typography>
            ) : (
              <HeadingText variant="h3" color="white" pageName={currentURL} />
            )}

            <MainMenu />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <ThemeProvider theme={theme}>
        <Box sx={{height:50}} />
      </ThemeProvider>
    </React.Fragment>
  );
}
