import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <>
      <Link to="/">Back</Link>
      <h3>WQMS v1.0</h3>
      <h4>Copyright: Lionsgate Water Treatment Ltd. All rights reserverd.</h4>
    </>
  );
}
