import { action, thunk, createStore, computed } from "easy-peasy";
import { DateTime } from "luxon";

import { getTelemetryForTime, getDeviceHierarchy, getRules } from "./api.mjs";
import OrganizationTree from "./OrganizationTree.mjs";
import Rule from "./Rule.mjs";

const waterPropertyLabels = {
  dischflow: "Discharge Flow",
  dischvol: "Discharge Volume",
  flowval: "Flow Value",
  ph: "pH",
  recirflow: "Recirculation Flow",
  recirvol: "Recirculation Volume",
  temp: "Temperature",
  turb: "Turbidity",
};

const expectedWaterSampleProps = {
  dischflow: true,
  dischvol: true,
  flowval: true,
  ph: true,
  recirflow: true,
  recirvol: true,
  temp: true,
  turb: true,
};

const ignoredWaterSampleProps = {
  location: true,
  pressure: true,
  humidity: true,
  tempc: true,
  description: true,
  maxtemp: true,
  rain: true,
};

/**
 * Make sure we have the basic props in a sample. If not add them with null values by default.
 * This is done to prevent crashes due to frequent missing water properties in telemetry data.
 */
function hydrateWaterSample(waterSample) {
  for (let expectedWaterProperty in expectedWaterSampleProps) {
    if (!waterSample.hasOwnProperty(expectedWaterProperty)) {
      waterSample[expectedWaterProperty];
    }
  }
}

/**
 * Round float values only. Return all other values unchanged.
 * @param {*} value this can be of many types, but we round only if this is a float value
 * @param {*} precision how may decimals we want as a result
 * @returns
 */
function roundFloat(value, precision) {
  // check if the passed value is a number
  if (typeof value == "number" && !isNaN(value)) {
    // if it is not integer, it's float
    if (!Number.isInteger(value)) {
      const multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
  }

  return value;
}

function upsertMap(map, itemKey, itemData) {
  if (map.has(itemKey)) {
    map.get(itemKey).push(itemData);
  } else {
    map.set(itemKey, [itemData]);
    //update legend
    if (!map.get("legend").hasOwnProperty(itemKey)) {
      map.get("legend")[itemKey] = String(itemKey).toUpperCase();
    }
  }
}

function getTelemetryConfig(state, payload) {
  const nowUTC = DateTime.now().toUTC();
  let fromISODate = null;
  let toISODate = nowUTC.toISO();

  switch (state.telemetry.telemetryTimeWindow) {
    case "1h":
      fromISODate = nowUTC.minus({ hours: 1 }).toISO();
      break;
    case "6h":
      fromISODate = nowUTC.minus({ hours: 6 }).toISO();
      break;
    case "1d":
      fromISODate = nowUTC.minus({ days: 1 }).toISO();
      break;
    case "1w":
      fromISODate = nowUTC.minus({ weeks: 1 }).toISO();
      break;
    default:
      console.error(
        "Unhandled duration was selected!",
        state.telemetry.telemetryTimeWindow,
      );
      break;
  }

  return {
    isRealtime: !!payload,
    user: state.currentUser,
    deviceId: state.currentDeviceId,
    fromISODate,
    toISODate,
  };
}

const store = createStore({
  //global props, actions...
  currentUser: null,

  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),

  updateUser: thunk(async (actions, payload) => {
    try {
      await actions.orgHierarchy.getDeviceHierarchy(payload).then((data) => {
        actions.setCurrentUser(payload);
      });
    } catch (error) {
      console.error(error);
    }
  }),

  currentDeviceId: null,
  currentProjectId: null,

  currentProjId: computed((state) => {
    return state.currentProjectId;
  }),

  currentProjectName: computed((state) => state.currentDeviceId),

  setCurrentDeviceId: action((state, payload) => {
    state.currentDeviceId = payload;
  }),

  setCurrentProjectId: action((state, payload) => {
    state.currentProjectId = payload;
  }),

  //begin scoped props, actions...
  telemetry: {
    data: null,
    telemetryTimeWindow: "1h",
    polling: -1,
    pollingInterval: 60000,
    pollingCounter: 0,
    maxTimePolling: 5,

    timeWindow: computed((state) => state.telemetryTimeWindow),

    setTimeWindow: action((state, payload) => {
      state.telemetryTimeWindow = payload;
    }),

    setPolling: action((state, payload) => {
      state.polling = payload;
      //console.info("PollingId: ", state.polling);
    }),

    setPollingCounter: action((state, payload) => {
      state.pollingCounter = payload;
    }),

    stopPolling: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();
      clearTimeout(state.polling);
      await actions.setPolling(-2);
      await actions.setPollingCounter(0);
      console.info("Polling cleared...");
    }),

    updatePollingCounter: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();

      if (state.pollingCounter < state.maxTimePolling) {
        await actions.setPollingCounter(state.pollingCounter + 1);
      } else {
        await actions.stopPolling();
      }
    }),

    formatTelemetry: action((state, payload) => {
      if (!payload) return;
      const rawTelemetry = payload;
      const chartsDataMap = new Map();

      chartsDataMap.set("legend", waterPropertyLabels);

      for (let telemetryDatum of rawTelemetry) {
        upsertMap(
          chartsDataMap,
          "sampleDate",
          DateTime.fromISO(telemetryDatum?.enqueuedTime),
        );

        let waterSample = telemetryDatum?.telemetry;
        //make sure we have the basic properties in the sample
        hydrateWaterSample(waterSample);

        for (let waterProperty in waterSample) {
          if (expectedWaterSampleProps[waterProperty]) {
            upsertMap(
              chartsDataMap,
              waterProperty,
              roundFloat(waterSample[waterProperty], 2),
            );
          } else if (ignoredWaterSampleProps[waterProperty]) {
            //console.info("Ignoring water property: ", waterProperty);
          } else {
            console.warn("Unexpected water property detected: ", waterProperty);
          }
        }
      }

      state.data = chartsDataMap;
    }),

    getTelemetryForTime: thunk(async (actions, payload, helpers) => {
      try {
        const config = getTelemetryConfig(helpers.getStoreState(), payload);
        await getTelemetryForTime(config).then((data) => {
          actions.formatTelemetry(data);
        });
      } catch (error) {
        console.error(error);
      }
    }),

    pollTelemetryForTime: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();

      if (state.polling < -1) {
        return; //exit polling
      }

      try {
        await actions.getTelemetryForTime(payload);
      } catch (error) {
        console.error("Error polling telemetry:", error);
        actions.stopPolling();
      } finally {
        if (state.polling < -1) {
          return; //exit polling
        }
        const pollingID = setTimeout(
          () => actions.pollTelemetryForTime(payload),
          state.pollingInterval,
        ); // Poll again after 60 seconds
        actions.setPolling(pollingID);
        await actions.updatePollingCounter();
      }
    }),
  },

  orgHierarchy: {
    data: null,

    currentProject: computed((state) => {
      return (projId) => state.data?.getOrganizationById(projId);
    }),

    formatHierarchy: action((state, payload) => {
      if (payload) {
        state.data = new OrganizationTree(payload);
      }
    }),

    getDeviceHierarchy: thunk(async (actions, payload, helpers) => {
      try {
        const userId = payload ? payload : helpers.getStoreState().currentUser;
        if (userId) {
          await getDeviceHierarchy(userId).then((data) => {
            actions.formatHierarchy(data);
          });
        }
      } catch (error) {
        console.error(error);
      }
    }),
  },

  rules: {
    data: null,

    formatRules: action((state, payload) => {
      if (payload) {
        const rules = payload?.map(
          (rule) =>
            new Rule(
              rule.id,
              rule.orgId,
              rule.isEnabled,
              rule.conditions,
              rule.displayName,
              rule.deviceTemplate,
              rule.severity,
            ),
        );

        state.data = rules;
      }
    }),

    getRules: thunk(async (actions, payload, helpers) => {
      try {
        const userId = payload ? payload : helpers.getStoreState().currentUser;
        await getRules(userId).then((data) => {
          actions.formatRules(data);
        });
      } catch (error) {
        console.error(error);
      }
    }),
  },
});

export default store;
