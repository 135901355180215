import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { useStoreActions, useStoreState } from "easy-peasy";

import { ArrowDropDown } from "@mui/icons-material";

import DeviceList from "../components/DeviceList.mjs";

function Projects({ organizationTree }) {
  return (
    <Box>
      {organizationTree.childNodes > 0 ? (
        <Project data={organizationTree.childNodes} />
      ) : (
        <DeviceList data={organizationTree} />
      )}
    </Box>
  );
}

function Project({ data }) {
  return (
    <>
      {data.map((parent) => {
        return (
          <Accordion key={parent.orgId}>
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDropDown />}
            >
              <Typography>{parent.displayName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {parent.childNodes.length > 0 ? (
                <Project data={parent.childNodes} />
              ) : (
                <DeviceList data={parent} />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

function ProjectTree({ organizationTree }) {
  return (
    <Box>
      {organizationTree.childNodes.length > 0 ? (
        <Project data={organizationTree.childNodes} />
      ) : (
        <DeviceList data={organizationTree} />
      )}
    </Box>
  );
}

function ProjectList({ projects }) {
  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      {projects.map((project) => (
        <DeviceList key={project.orgId} data={project} />
      ))}
    </Box>
  );
}

export default function Projects() {
  const loadDeviceHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);

  const [syncNeeded, setSyncNeeded] = useState(false);

  const performSync = function () {
    loadDeviceHierarchy();
  };

  function startLoadingData(event) {
    console.debug(`Received message from service worker:`, event?.data.msg);
    if (event?.data.msg === "activated") {
      setSyncNeeded(true);
    } else {
      console.error("Unhandled message from service worker: ", event?.data.msg);
    }
  }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.removeEventListener("message", startLoadingData);
      navigator.serviceWorker.addEventListener("message", startLoadingData);
    }
    //loadDeviceHierarchy();
  }, []);

  useEffect(() => {
    if (syncNeeded) {
      performSync();
    }

    setSyncNeeded(false);
  }, [syncNeeded]);

  /*<ProjectTree organizationTree={deviceHierarchy.getProjects()} />*/

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!deviceHierarchy?.tree ? (
        <Box>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
          <Skeleton
            sx={{ marginTop: "0.2em" }}
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
        </Box>
      ) : (
        <ProjectList projects={deviceHierarchy.getProjects()} />
      )}
    </Box>
  );
}
