import ProjectContact from "./ProjectContact.mjs";
/**
 * Description of the project node.  We need to keep it separate from the organization hierarchy,
 * because organization hierarchy gets recretaed frequently and we want to keep the project node
 * details saved in a different object, but in the same container with org tree.
 */
export default class ProjectDecription {
  // Static enum-like property usage new ProjectDecription(ProjectDecription.State.GOOD)
  static State = Object.freeze({
    CRITICAL: "critical",
    DANGER: "danger",
    WARNING: "warning",
    GOOD: "good",
    OFFLINE: "offline",
  });

  //(read-only) when in the organizaitons container we need a way to differentiate between organizations and org details and rules.
  #recordType = "orgProperty";
  #id;
  #orgId;
  #address;
  #projectReference;
  #serialNumber;
  #escPermit;
  #escDesigner;
  #escMonitor;
  #maintenanceInterval;
  #installerName;
  #flowRate;
  #planId;
  #location;
  #lastVisit;
  #lastMaintenance;
  #lastSeriousRainfallEvent;
  #lastDischarge;
  #dischargeDestination;
  #wqCompliance;
  #isActive;
  #valvePosition;
  #healthState;
  #contacts;
  #description;

  /**
   * Creates an instance of the ProjectDescription.
   * @param {number} id - The unique identifier for the ProjectDescription.
   * @param {number} orgId - What node this description belongs to in the hierarchy tree.
   * @param {string} address - current project deployment address.
   * @param {string} projectReference - project reference # 23-3329.
   * @param {string} serialNumber - Renaissance Unit Serial# RM-WMS-001.
   * @param {string} escPermit - permit number xxxx-xxxx-xx.
   * @param {string} escDesigner - Renaissance Environmental.
   * @param {string} escMonitor - Flowlink by default.
   * @param {number} maintenanceInterval - The maintenance interval (weekly).
   * @param {string} installerName - For example: Mathew L, Steven Thomas.
   * @param {number} flowRate - The flow rate for this project.
   * @param {number} planId - The municipal plan Id.
   * @param {Array<string>} location - The location [long, lat].
   * @param {Date} lastVisit - The date of the last visit.
   * @param {Date} lastMaintenance - The date of the last maintenance.
   * @param {Date} lastSeriousRainfallEvent - The date of the last serious rainfall event.
   * @param {Date} lastDischarge - The date of the last discharge.
   * @param {string} dischargeDestination - The discharge destination (storm sewer).
   * @param {boolean} wqCompliance - The water quality compliance status (6.5-8.5 pH, 0-50 NTU).
   * @param {boolean} isActive - Indicates if the instance is active.
   * @param {boolean} valvePosition - The position of the valve (Open true/Closed false).
   * @param {ProjectDecription.State} healthState - The health state ProjectDecription.State.GOOD.
   * @param {Array<ProjectContact>} contacts - The list of contacts.
   * @param {string} description - The generic description.
   */
  constructor(
    id = "N/A",
    orgId = "N/A",
    address = "N/A",
    projectReference = "N/A",
    serialNumber = "N/A",
    escPermit = "N/A",
    escDesigner = "Renaissance Environmental",
    escMonitor = "Flowlink",
    maintenanceInterval = "weekly",
    installerName = "N/A",
    flowRate = "N/A",
    planId = "N/A",
    location = [],
    lastVisit = "Date Not Available",
    lastMaintenance = "Date Not Available",
    lastSeriousRainfallEvent = "Date Not Available",
    lastDischarge = "Date Not Available",
    dischargeDestination = "N/A",
    wqCompliance = "N/A",
    isActive = false,
    valvePosition = "N/A",
    healthState = ProjectDecription.State.OFFLINE,
    contacts = [],
    description = "",
  ) {
    this.#id = id;
    this.#orgId = orgId;
    this.#address = address;
    this.#projectReference = projectReference;
    this.#serialNumber = serialNumber;
    this.#escPermit = escPermit;
    this.#escDesigner = escDesigner;
    this.#escMonitor = escMonitor;
    this.#maintenanceInterval = maintenanceInterval;
    this.#installerName = installerName;
    this.#flowRate = flowRate;
    this.#planId = planId;
    this.#location = location;
    this.#lastVisit = lastVisit;
    this.#lastMaintenance = lastMaintenance;
    this.#lastSeriousRainfallEvent = lastSeriousRainfallEvent;
    this.#lastDischarge = lastDischarge;
    this.#dischargeDestination = dischargeDestination;
    this.#wqCompliance = wqCompliance;
    this.#isActive = isActive;
    this.#valvePosition = valvePosition;
    this.#healthState = healthState;
    this.#contacts = contacts?.map((c) => new ProjectContact(c));
    this.#description = description;
  }

  get recordType() {
    return this.#recordType;
  }

  get id() {
    return this.#id;
  }

  set id(value) {
    this.#id = value;
  }

  get orgId() {
    return this.#orgId;
  }

  set orgId(value) {
    this.#orgId = value;
  }

  get address() {
    return this.#address;
  }

  set address(value) {
    this.#address = value;
  }

  get projectReference() {
    return this.#projectReference;
  }

  set projectReference(value) {
    this.#projectReference = value;
  }

  get serialNumber() {
    return this.#serialNumber;
  }

  set serialNumber(value) {
    this.#serialNumber = value;
  }

  get escPermit() {
    return this.#escPermit;
  }

  set escPermit(value) {
    this.#escPermit = value;
  }

  get escDesigner() {
    return this.#escDesigner;
  }

  set escDesigner(value) {
    this.#escDesigner = value;
  }

  get escMonitor() {
    return this.#escMonitor;
  }

  set escMonitor(value) {
    this.#escMonitor = value;
  }

  get maintenanceInterval() {
    return this.#maintenanceInterval;
  }

  set maintenanceInterval(value) {
    this.#maintenanceInterval = value;
  }

  get installerName() {
    return this.#installerName;
  }

  set installerName(value) {
    this.#installerName = value;
  }

  get flowRate() {
    return this.#flowRate;
  }

  set flowRate(value) {
    this.#flowRate = value;
  }

  get planId() {
    return this.#planId;
  }

  set planId(value) {
    this.#planId = value;
  }

  get location() {
    return this.#location;
  }

  set location(value) {
    this.#location = value;
  }

  get lastVisit() {
    return this.#lastVisit;
  }

  set lastVisit(value) {
    this.#lastVisit = value;
  }

  get lastMaintenance() {
    return this.#lastMaintenance;
  }

  set lastMaintenance(value) {
    this.#lastMaintenance = value;
  }

  get lastSeriousRainfallEvent() {
    return this.#lastSeriousRainfallEvent;
  }

  set lastSeriousRainfallEvent(value) {
    this.#lastSeriousRainfallEvent = value;
  }

  get lastDischarge() {
    return this.#lastDischarge;
  }

  set lastDischarge(value) {
    this.#lastDischarge = value;
  }

  get dischargeDestination() {
    return this.#dischargeDestination;
  }

  set dischargeDestination(value) {
    this.#dischargeDestination = value;
  }

  get wqCompliance() {
    return this.#wqCompliance;
  }

  set wqCompliance(value) {
    this.#wqCompliance = value;
  }

  get isActive() {
    return this.#isActive;
  }

  set isActive(value) {
    this.#isActive = value;
  }

  get valvePosition() {
    return this.#valvePosition;
  }

  set valvePosition(value) {
    this.#valvePosition = value;
  }

  get healthState() {
    return this.#healthState;
  }

  set healthState(value) {
    this.#healthState = value;
  }

  get contacts() {
    return this.#contacts;
  }

  set contacts(value) {
    this.#contacts = value;
  }

  get description() {
    return this.#description;
  }

  set description(value) {
    this.#description = value;
  }

  clone() {
    return new ProjectDecription(
      this.#id,
      this.#orgId,
      this.#address,
      this.#projectReference,
      this.#serialNumber,
      this.#escPermit,
      this.#escDesigner,
      this.#escMonitor,
      this.#maintenanceInterval,
      this.#installerName,
      this.#flowRate,
      this.#planId,
      this.#location,
      this.#lastVisit,
      this.#lastMaintenance,
      this.#lastSeriousRainfallEvent,
      this.#lastDischarge,
      this.#dischargeDestination,
      this.#wqCompliance,
      this.#isActive,
      this.#valvePosition,
      this.#healthState,
      this.#contacts.map((contact) => contact.clone()),
      this.#description,
    );
  }

  toJSON() {
    return {
      recordType: this.#recordType,
      id: this.#id,
      orgId: this.#orgId,
      address: this.#address,
      contacts: this.#contacts,
      description: this.#description,
      dischargeDestination: this.#dischargeDestination,
      escPermit: this.#escPermit,
      escDesigner: this.#escDesigner,
      escMonitor: this.#escMonitor,
      flowRate: this.#flowRate,
      healthState: this.#healthState,
      isActive: this.#isActive,
      installerName: this.#isActive,
      maintenanceInterval: this.#maintenanceInterval,
      lastDischarge: this.#lastDischarge,
      lastMaintenance: this.#lastMaintenance,
      lastSeriousRainfallEvent: this.#lastSeriousRainfallEvent,
      lastVisit: this.#lastVisit,
      location: this.#location,
      planId: this.#planId,
      projectReference: this.#projectReference,
      serialNumber: this.#serialNumber,
      wqCompliance: this.#wqCompliance,
      valvePosition: this.#valvePosition,
    };
  }
}
