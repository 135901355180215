import React from "react";
import AzureLocationMap from "../components/AzureLocationMap.mjs";

export default function Location() {
  //Add a <nav> list of users projects
  //Map of marked locations for each project
  return (
    <>
      <h3>WQMS v1.0 Location</h3>

      <AzureLocationMap/>

      <h4>Copyright: Lionsgate Water Treatment Ltd. All rights reserved.</h4>
    </>
  );
}
