import React from "react";
import { Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useStoreActions, useStoreState } from "easy-peasy";
import { experimentalStyled as styled } from "@mui/material/styles";



export default function ToggleButtons() {
  const timeWindow = useStoreState((state) => state.telemetry.timeWindow);
  const setTimeWindow = useStoreActions(
    (actions) => actions.telemetry.setTimeWindow,
  );

  const handleDuration = (event, duration) => {
    setTimeWindow(duration);
  };
	const DeviceToggleButton = styled(ToggleButton)(({ theme }) => ({
		minWidth: 60,
		borderRadius:"6px",
		backgroundColor:"#D9D9D933",
		borderColor:"#D9D9D933",
		color: "#000000CC",
		fontSize:"16px",
		"&:hover":{ background:"#005BD4", color: "white"},
		"&.MuiToggleButtonGroup-middleButton":{borderRadius:"6px", border:"1px solid #D9D9D933"},
		"&.MuiToggleButtonGroup-firstButton":{borderRadius:"6px", border:"1px solid #D9D9D933"},
		"&.MuiToggleButtonGroup-lastButton":{borderRadius:"6px", border:"1px solid #D9D9D933"},
		"& p":{fontWeight:"bold"},
		"&.Mui-selected":{background:"white", border:"white"},
		"&:hover":{background:"#D9D9D966"}
		
	})); 

  return (
    <ToggleButtonGroup
      value={timeWindow}
      exclusive
      onChange={handleDuration}
      aria-label="Telemetry time window selector"
      size="small"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
				gap:"10px"
      }}
    >
      <DeviceToggleButton value="1h" aria-label="1 hour">
        <Typography>{"1h"}</Typography>
      </DeviceToggleButton>
      <DeviceToggleButton value="6h" aria-label="6 hours">
        <Typography>{"6h"}</Typography>
      </DeviceToggleButton>
      <DeviceToggleButton value="1d" aria-label="1 day">
        <Typography>{"1d"}</Typography>
      </DeviceToggleButton>
      <DeviceToggleButton value="1w" aria-label="1 week" disabled>
        <Typography>{"1w"}</Typography>
      </DeviceToggleButton>
    </ToggleButtonGroup>
  );
}
