import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Select,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RuleIcon from "@mui/icons-material/Rule";
import LogoutIcon from '@mui/icons-material/Logout';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useMsal } from "@azure/msal-react";

export default function MainMenu() {
  const currentUser = useStoreState((state) => state.currentUser);
  const [state, setState] = React.useState({
    isDrawerOpened: false,
  });

  const [selectedUser, setSelectedUser] = React.useState(currentUser);
  const { instance } = useMsal();

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, isDrawerOpened: isOpen });
  };

  const updateUser = useStoreActions((actions) => actions.updateUser);

  const handleListItemClick = (event, user) => {
    updateUser(user);
    setSelectedUser(user);
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <React.Fragment key="right">
      <IconButton
        edge="start"
        color="inherit"
        sx={{ fontSize: 40, p: 0, m: 0 }}
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon sx={{ fontSize: 40 }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={state["isDrawerOpened"]}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ 
						width: 150,
						"& .MuiListItemIcon-root":{
							minWidth:"36px"
						}
					}}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem key="Settings" disablePadding>
              <ListItemButton component={Link} to="settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Rules" disablePadding>
              <ListItemButton component={Link} to="rules">
                <ListItemIcon>
                  <RuleIcon />
                </ListItemIcon>
                <ListItemText primary="Rules" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Alerts" disablePadding>
              <ListItemButton component={Link} to="alerts">
                <ListItemIcon>
                  <SdCardAlertIcon />
                </ListItemIcon>
                <ListItemText primary="Alerts" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Events" disablePadding>
              <ListItemButton component={Link} to="events">
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText primary="Events" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Locations" disablePadding>
              <ListItemButton component={Link} to="locations">
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Locations" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Contacts" disablePadding>
              <ListItemButton component={Link} to="contacts">
                <ListItemIcon>
                  <ContactPageIcon />
                </ListItemIcon>
                <ListItemText primary="Contacts" />
              </ListItemButton>
            </ListItem>
            <ListItem key="User1" disablePadding>
              <ListItemButton
                selected={selectedUser === "guest@renaissanceenv.com"}
                onClick={(event) =>
                  handleListItemClick(event, "guest@renaissanceenv.com")
                }
              >
                <ListItemText primary="Admin" />
              </ListItemButton>
            </ListItem>
            <ListItem key="User2" disablePadding>
              <ListItemButton
                selected={selectedUser === "graystone@gmail.com"}
                onClick={(event) =>
                  handleListItemClick(event, "graystone@gmail.com")
                }
              >
                <ListItemText primary="Graysone" />
              </ListItemButton>
            </ListItem>
            <ListItem key="About" disablePadding>
              <ListItemButton component={Link} to="about">
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Signout" disablePadding>
              <ListItemButton onClick={handleLogoutRedirect}>
							<ListItemIcon>
							<LogoutIcon/>
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
